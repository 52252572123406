document.addEventListener('DOMContentLoaded',function() {

    window.estate = function() {
        const estate = document.getElementsByClassName('js-estate')[0];
        const legend = estate.getElementsByClassName('js-legend')[0];
        const map = estate.getElementsByClassName('js-map')[0];
        const homebox = estate.getElementsByClassName('js-homebox');
        const houseOnMap = map.getElementsByClassName('js-house');
        const realizations = estate.getElementsByClassName('js-realizations')[0];
        let start = false;
        
        const houses = function() {
            
            const showDetails = function(e) {
                const _this = e.currentTarget;
                const id = _this.id;
                
                for (let i = 0; i < homebox.length; i++) {
                    homebox[i].classList.remove('is-visible', 'is-active');
                    houseOnMap[i].classList.remove('is-active');
                }
                
                for (let i = 0; i < homebox.length; i++) {
                    if (homebox[i].dataset.id === id) {

                        homebox[i].classList.add('is-visible');
                        
                        setTimeout(function() {
                            homebox[i].classList.add('is-active');
                        }, 10);
                    }
                }
                
                _this.classList.add('is-active');
                
/*
                if (start === false) {
                    estate.getElementsByClassName('on-start')[0].classList.remove('on-start');
                    start = true;
                }
*/


                //if (document.documentElement.classList.contains('mobile')) {

                    scroll({
                        top: document.getElementsByClassName('js-realizations')[0].getBoundingClientRect().top + window.scrollY - 50,
                        behavior: "smooth"
                    })

                //}
            };
            
            const hover = function(e) {
                e.currentTarget.classList.add('on-hover');
            }

            for (let i = 0; i < houseOnMap.length; i++) {
                houseOnMap[i].addEventListener('click', showDetails);                

                houseOnMap[i].addEventListener('mouseenter', function(e) {
                    e.currentTarget.classList.add('on-hover');
                });
                
                houseOnMap[i].addEventListener('mouseleave', function(e) {
                    e.currentTarget.classList.remove('on-hover');
                });
            }
        }

        const filters = function() {
            const btn = legend.getElementsByClassName('js-btn');
            
            const filterEstate = function(e) {
                const _this = e.currentTarget;
                
                realizations.getElementsByClassName('is-active')[0] ?
                        realizations.getElementsByClassName('is-active')[0].classList.remove('is-active', 'is-visible') : false;
                
                if (_this.classList.contains('is-active')) {
                    _this.classList.remove('is-active'); 
                   
                    for (let i = 0; i < houseOnMap.length; i++) {
                        houseOnMap[i].classList.remove('is-visible', 'is-active');
                    }
                    
                } else {                    
                    
                    for(let i = 0; i < btn.length; i++) {
                        btn[i].classList.remove('is-active');
                    }
                    
                    for (let i = 0; i < houseOnMap.length; i++) {
                        houseOnMap[i].classList.remove('is-visible', 'is-active');
                    }
    
                    for (let i = 0; i < houseOnMap.length; i++) {
                        if (houseOnMap[i].classList.contains(_this.dataset.type) ) {
                            houseOnMap[i].classList.add('is-visible');
                        }
                    }
                    
                    _this.classList.add('is-active'); 
                }
            }
            
            for(let i = 0; i < btn.length; i++) {
                btn[i].addEventListener('click', filterEstate);
            }
        }

        filters();
        houses();
    }

}, false)
