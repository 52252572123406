import css from './sass/style.scss';

require('./js/polyfills.js');
require('./js/cookies.js');
require('./js/esetate.js');
require('./js/helpers.js');
require('./js/init.js');
require('./js/fonts.js');
require('./js/inview.js');
require('./js/ismobile.js');
