document.addEventListener('DOMContentLoaded',function() {

    const cover = document.getElementById('cover');
    
    const init = function() {
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');
        
        window.beforeAnimsInit();
        document.getElementsByClassName('js-estate')[0] ? window.estate() : false;
        
        if (cover) {
            setTimeout(function() {
                cover.remove();
                window.animsInit();
            }, 250);  
        }
    };
    
    window.addEventListener('load', init);

}, false);